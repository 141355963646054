<template>

  <TouchPad
    @leverchange="UpdatePad($event)"
    @touchStart="TouchStart($event)"
    @touchRelease="TouchRelease($event)"
    @buttonchange="ButtonChange($event)"
    :shape="block.shape"
    :buttons="block.buttons"/>

</template>
<script>

import TouchPad from '@/components/externals/TouchPad.vue';
import { roundTo } from "@/components/utils";

export default {
  components: {
    TouchPad
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxDecimals: 2,
      EmitCoolingDown: false,
      joystickData: {
        x: 0,
        y: 0,
        speed: 0,
        angle: 0,
        radians: 0,
      },
    };
  },
  computed: {},
  methods: {
    TouchStart(){
      this.$parent.scrollDisabled = true
      // Envio pressed
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'touchPad',
        name: this.block.blockName,
        input:{
          type: 'pressed',
          pressed: true
        }
      })
    },
    TouchRelease(){
      this.$parent.scrollDisabled = false
      // Envio pressed
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'touchPad',
        name: this.block.blockName,
        input:{
          type: 'pressed',
          pressed: false
        }
      })
    },
    UpdatePad({speed, angle, x,y }) {
      const joy = this.joystickData
      joy.x = x
      joy.y = y
      joy.speed = speed
      joy.angle = angle

      if (this.EmitCoolingDown) return  // cooldown

      if(this.block.shape == 'circle') {
         this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'touchPad',
          name: this.block.blockName,
          data: {
            shape: 'circle',
            angle: joy.angle,
            distance: roundTo(joy.speed,this.maxDecimals)
          }
        })
      } else {
        this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'touchPad',
          name: this.block.blockName,
          input: {
            shape: 'square',
            x: roundTo(joy.x,this.maxDecimals),
            y: roundTo(joy.y,this.maxDecimals)
          }
        })
      }

      this.EmitCoolingDown = true;
      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.block.dataInterval)
    },
  },
  mounted() {
    //
  },
};
</script>
